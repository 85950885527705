import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import store from '@/store';
import RouterUtil from '@/utils/router.util';

Vue.use(VueRouter);

const PAGES_UNDER_MAINTENANCE = process.env.VUE_APP_PAGES_UNDER_MAINTENANCE;

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const Login = () => import(/* webpackChunkName: "Login" */'@/views/Login/Login.vue');
const UserNotAuthorized = () => import(/* webpackChunkName: "UserNotAuthorized" */'@/views/Login/UserNotAuthorized.vue');
const Logout = () => import(/* webpackChunkName: "Logout" */'@/views/Login/Logout.vue');
const NotFound = () => import(/* webpackChunkName: "NotFound" */'@/views/NotFound.vue');
const Users = () => import(/* webpackChunkName: "Users" */'@/views/Admin/Users/Users.vue');
const Groups = () => import(/* webpackChunkName: "Groups" */'@/views/Admin/Groups/Groups.vue');
const Configs = () => import(/* webpackChunkName: "Configs" */'@/views/Admin/Configs/Configs.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "EmptyRouterView" */'@/components/EmptyRouterView.vue');
const SectionChildrenCards = () => import(/* webpackChunkName: "SectionChildrenCards" */'@/views/Researchs/SectionChildrenCards.vue');
const IFramePage = () => import(/* webpackChunkName: "IFramePage" */'@/components/IFramePage.vue');
const UnderMaintenancePage = () => import(/* webpackChunkName: "IFramePage" */'@/components/UnderMaintenancePage.vue');
const DailyAudience = () => import(/* webpackChunkName: "DailyAudience" */'@/views/Researchs/DailyAudience.vue');
const DailyGrid = () => import(/* webpackChunkName: "DailyGrid" */'@/views/Researchs/DailyGrid.vue');
const MinuteByMinute = () => import(/* webpackChunkName: "MinuteByMinute" */'@/views/Researchs/MinuteByMinute.vue');
const ConsolidatedByMarketBySlot = () => import(/* webpackChunkName: "ConsolidatedByMarketBySlot" */'@/views/Researchs/ConsolidatedByMarketBySlot.vue');
const ConsolidatedByMarketByProgram = () => import(/* webpackChunkName: "ConsolidatedByMarketByProgram" */'@/views/Researchs/ConsolidatedByMarketByProgram.vue');
const ConsolidatedBySlot = () => import(/* webpackChunkName: "ConsolidatedBySlot" */'@/views/Researchs/ConsolidatedBySlot.vue');
const ConsolidatedByProgram = () => import(/* webpackChunkName: "ConsolidatedByProgram" */'@/views/Researchs/ConsolidatedByProgram.vue');
const RankingOfTvNetworks = () => import(/* webpackChunkName: "RankingOfTvNetworks" */ '@/views/Researchs/RankingOfTvNetworks.vue');
const RankingOfPrograms = () => import(/* webpackChunkName: "RankingOfPrograms" */ '@/views/Researchs/RankingOfPrograms.vue');

const routes: Array<RouteConfig> = [
  // Home
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      hiddenOnNavigation: true,
    }
  },
  {
    path: '/pagina-em-manutencao',
    name: 'pagina-em-manutencao',
    component: UnderMaintenancePage,
    meta: {
      hiddenOnNavigation: true,
    }
  },
  //Login
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      label: 'Login',
      hiddenOnNavigation: true,
      customColors:['#02EBFE','#1297DF','#6C66ED'],
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      hiddenOnNavigation: true,
      customColors:['#02EBFE','#1297DF','#6C66ED'],
    },
  },

  // Audiência Multiplataforma
    {
      path:'/audiencia-multiplataforma',
      component: EmptyRouterView,
      meta: {
        label: 'Audiência Multiplataforma',
        customColors: ['#CA2561', '#842F7A', '#174E8E'],
        description: `Total de indivíduos alcançados pelos conteúdos Globo nos canais lineares e digitais
        , sem duplicidade.<br/>Fonte: Comscore Kantar Ibope Media, Google Analytics e Pesquisa Multiplataforma`,
        imageFile: 'section-icons/multiplataform-icon.png',
      },
      children:[
        {
          path: '',
          name: 'audiencia-multiplataforma',
          component: SectionChildrenCards,
          meta: {
            label: 'Audiência Multiplataforma',
            customColors: ['#CA2561', '#842F7A', '#174E8E'],
            imageFile: 'section-icons/multiplataform-icon.png',
            hiddenOnNavigation: true,
          }
        },
        {
          path: 'total-audience',
          name: 'total-audience',
          component: IFramePage,
          meta: {
            label: 'Total Audience',
            customColors: ['#CA2561', '#A40EC1'],
            requiresPermission: 'isTotalAudience',
            description: `Total desduplicado de pessoas alcançadas pelo ecossistema Globo (TV Globo, Canais Pagos Globo, Globoplay e Publishings) no total dia e por programas, diariamente ou semanalmente, além da jornada dos consumidores nas propriedades digitais Globo.`,
            imageFile: 'section-icons/audience.png',
            iFrameSrc: 'https://lookerstudio.google.com/embed/reporting/45618124-17fa-4c9c-b534-160644f2f135/page/p_pnsjd0fpbd',
          }
        },
        {
          path: 'painel-integrado-bbb',
          name: 'painel-integrado-bbb',
          component: IFramePage,
          meta: {
            label: 'Painel Integrado BBB24',
            customColors:  ['#6621FA', '#EC282A'],
            requiresPermission: 'isIntegratedPanelBBB',
            description: `Audiência, alcance e perfil dos consumidores do BBB24 na TV Globo, Multishow, Globoplay e Gshow, consumo do podcast Mesacast e engajamento nos perfis oficiais da Globo (Facebook, Instagram, Youtube e Tiktok) e o alcance multiplataforma do programa em todas as telas.`,
            imageFile: 'section-icons/bbb-panel.png',
            iFrameSrc: 'https://lookerstudio.google.com/embed/reporting/0e08f3a1-9c8c-4384-b4a8-2f834c4ce3fc/page/p_7smky4f70c',
          }
        },
        {
          path: 'cross-plataform-view',
          name: 'cross-plataform-view',
          component: IFramePage,
          meta: {
            label: 'Cross Plataform View',
            customColors: ['#6621FA', '#EC282A'],
            requiresPermission: 'isCrossPlataformView',
            description: `Dados de audiência, share, alcance diário e alcance acumulado de canais de TV Aberta,
             TV Paga e Plataformas de Video dentro de casa, através da TV/CTV, smartphone, tablet e desktop,
              por dia e de hora em hora. Fonte: Kantar Ibope Media.`,
            imageFile: 'section-icons/cross-plataform-view.png',
            iFrameSrc: 'https://app.powerbi.com/reportEmbed?reportId=93c6e26d-7f62-41be-bc02-66779a7d7891&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
          }
        },
      ],
    },
  //Pesquisa Oficial
  {
    path:'/audiencia-de-tv-oficial',
    component: EmptyRouterView,
    meta: {
      label: 'Audiência de TV (Oficial)',
      customColors: ['#ECC941', '#7F317B', '#214B8C'],
      description: 'Audiência de TV consolidada em D+1.<br/>Fonte: Kantar IBOPE Media',
      imageFile: 'section-icons/pesquisa-oficial.png',
    },
    children:[
      {
        path: '',
        name: 'audiencia-de-tv-oficial',
        component: SectionChildrenCards,
        meta: {
          label: 'Audiência de TV (Oficial)',
          customColors: ['#ECC941', '#7F317B', '#214B8C'],
          imageFile: 'section-icons/pesquisa-oficial.png',
          hiddenOnNavigation: true,
        }
      },
      {
        path: 'audiencia-diaria',
        name: 'audiencia-diaria',
        component: DailyAudience,
        meta: {
          label: 'Audiência Diária',
          customColors: ['#893099', '#3F35A0'],
          requiresPermission: 'isDailyAudience',
          description: 'Audiência de um programa comparando com as audiências dos programas concorrentes de outras emissoras.',
          imageFile: 'section-icons/audiencia-diaria.png',
        }
      },
      {
        path: 'grade-diaria',
        name: 'grade-diaria',
        component: DailyGrid,
        meta: {
          label: 'Grade Diária',
          customColors: ['#DF1F9B', '#84309A'],
          requiresPermission: 'isDailyGrid',
          description: 'Audiência dos canais baseados nas suas grades confrontando com as audiências das emissoras concorrentes.',
          imageFile: 'section-icons/grade-diaria.png',
        }
      },
      {
        path: 'minuto-a-minuto',
        name: 'minuto-a-minuto',
        component: MinuteByMinute,
        meta: {
          label: 'Minuto a Minuto',
          customColors: ['#E70E31', '#C0298C'],
          requiresPermission: 'isMinuteByMinute',
          description: 'Audiência minuto a minuto das emissoras e suas concorrentes.',
          imageFile: 'section-icons/minuto-a-minuto.png',
        }
      },
      {
        path: 'ranking-canais',
        name: 'ranking-canais',
        component: RankingOfTvNetworks,
        meta: {
          label: 'Ranking de Canais',
          customColors: ['#FF7D00', '#EC282A'],
          requiresPermission: 'isChannelRanking',
          description: 'Ranking dos canais mais assistidos.',
          imageFile: 'section-icons/ranking-canais.png',
          //iFrameSrc: 'https://app.powerbi.com/reportEmbed?reportId=172519f6-2e68-4841-a79e-f6fcf4984e43&appId=eff166cc-9116-49be-ac57-2453dc452fc6&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWNlbnRyYWwtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
        }
      },
      {
        path: 'ranking-programas',
        name: 'ranking-programas',
        component: RankingOfPrograms,
        meta: {
          label: 'Ranking de Programas',
          customColors: ['#FFAE17', '#FF8407'],
          requiresPermission: 'isProgramRanking',
          description: 'Ranking com os programas mais assistidos.',
          imageFile: 'section-icons/ranking-programas.png',
          // iFrameSrc: 'https://app.powerbi.com/reportEmbed?reportId=f961645d-eebd-437c-9e0d-deb8cb08195c&appId=eff166cc-9116-49be-ac57-2453dc452fc6&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWNlbnRyYWwtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
        }
      },
      {
        path: 'consolidado-de-novelas',
        name: 'consolidado-de-novelas',
        component: IFramePage,
        meta: {
          label: 'Consolidado de Novelas',
          customColors: ['#DE1F9B', '#803199'],
          requiresPermission: 'isConsolidatedByNovel',
          description: 'Pesquisa de Consolidado de Novelas',
          imageFile: 'section-icons/consolidado-novelas.png',
          iFrameSrc: 'https://app.powerbi.com/reportEmbed?reportId=4bdb6802-af47-4994-b6b4-95806edffbbb&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWNlbnRyYWwtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
        }
      },
      {
        path: 'consolidado-por-praca-programa',
        name: 'consolidado-por-praca-programa',
        component: ConsolidatedByMarketByProgram,
        meta: {
          label: 'Consolidado por Praça - Programa',
          customColors: ['#019DDB', '#009952'],
          requiresPermission: 'isConsolidatedByMarketByProgram',
          description: 'Consolidado das audiências por praças de um determinado programa em um determinado período.',
          imageFile: 'section-icons/consolidado-praca-programa.png',
        }
      },
      {
        path: 'consolidado-por-praca-faixa',
        name: 'consolidado-por-praca-faixa',
        component: ConsolidatedByMarketBySlot,
        meta: {
          label: 'Consolidado por Praça - Faixa',
          customColors: ['#009A45', '#F9BD20'],
          requiresPermission: 'isConsolidatedByMarketBySlot',
          description: 'Consolidado das audiências por praças em um determinado período.',
          imageFile: 'section-icons/consolidado-praca-faixa.png',
        }
      },
      {
        path: 'consolidado-por-programa',
        name: 'consolidado-por-programa',
        component: ConsolidatedByProgram,
        meta: {
          label: 'Consolidado por Programa',
          customColors: ['#893099', '#3F35A0'],
          requiresPermission: 'isConsolidatedByProgram',
          description: 'Consolidados anuais, mensais e diários de um determinado programa.',
          imageFile: 'section-icons/consolidado-programa.png',
        }
      },
      {
        path: 'consolidado-por-faixa-horaria',
        name: 'consolidado-por-faixa-horaria',
        component: ConsolidatedBySlot,
        meta: {
          label: 'Consolidado por Faixa Horária',
          customColors: ['#0039A6', '#0587CF'],
          requiresPermission: 'isConsolidatedBySlot',
          description: 'Consolidados anuais, mensais e diários.',
          imageFile: 'section-icons/consolidado-faixa.png',
        }
      },
      {
        path: 'canais-pay-tv',
        name: 'canais-pay-tv',
        component: IFramePage,
        meta: {
          label: 'Canais Pay TV',
          customColors: ['#6621FA', '#EC282A'],
          requiresPermission: 'isPayTVChannels',
          description: `Perfil de audiência dos Canais Pagos Globo e demais concorrentes, por gênero,
           faixa etária, classe social e região, em diferentes faixas horárias, por ano, mês ou dia`,
          imageFile: 'section-icons/canais-pay-tv.png',
          iFrameSrc: 'https://app.powerbi.com/reportEmbed?reportId=e7629b5f-2a1f-4ba6-be9d-202a802fe643&appId=41638f47-a471-40f6-847d-7c3dc74ba9cc&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
        }
      },
      {
        path: 'programas-pay-tv',
        name: 'programas-pay-tv',
        component: IFramePage,
        meta: {
          label: 'Programas Pay TV',
          customColors: ['#6621FA', '#EC282A'],
          requiresPermission: 'isPayTVPrograms',
          description: `Perfil de audiência dos programas exibidos pelos Canais Pagos Globo e demais concorrentes,
           por gênero, faixa etária, classe social e região, em diferentes faixas horárias, por ano, mês ou dia.`,
          imageFile: 'section-icons/programas-pay-tv.png',
          iFrameSrc: 'https://app.powerbi.com/reportEmbed?reportId=f26a0c48-8283-4612-8231-806a623b929d&appId=41638f47-a471-40f6-847d-7c3dc74ba9cc&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6',
        }
      },
    ],
  },

  // Digital
  {
    path:'/audiencia-digital',
    component: EmptyRouterView,
    meta: {
      label: 'Audiência Digital',
      customColors: ['#CA2561', '#842F7A', '#174E8E'],
      description: 'Audiência digital de mercado.<br/>Fonte: Comscore',
      imageFile: 'section-icons/digital.png',
    },
    children:[
      {
        path: '',
        name: 'audiencia-digital',
        component: SectionChildrenCards,
        meta: {
          label: 'Audiência Digital',
          customColors: ['#CA2561', '#842F7A', '#174E8E'],
          imageFile: 'section-icons/digital.png',
          hiddenOnNavigation: true,
        }
      },
      {
        path: 'cenario-digital-brasileiro',
        name: 'cenario-digital-brasileiro',
        component: IFramePage,
        meta: {
          label: 'Cenário Digital Brasileiro',
          customColors: ['#893099', '#3F35A0'],
          requiresPermission: 'isBrazilianDigitalScene',
          description: 'Audiência de Internet.<br/>Fonte: Comscore',
          imageFile: 'section-icons/digital.png',
          // imageFile: 'section-icons/cenario-digital-brasileiro.png',
          iFrameSrc: 'https://datastudio.google.com/embed/u/0/reporting/14be2826-042e-4e12-8b89-f33e473fa3f9/page/p_x86zfw4gwc',
        }
      },
      {
        path: 'g1-performance-produto',
        name: 'g1-performance-produto',
        component: IFramePage,
        meta: {
          label: 'G1',
          customColors: ['#C4170C'],
          requiresPermission: 'isG1DigitalAnalytics',
          description: 'Performance de Produto.<br/>Fonte: Google Analytics',
          imageFile: 'section-icons/g1-branco.png',
          iFrameSrc: 'https://datastudio.google.com/embed/u/0/reporting/2cc27f00-7d78-4edf-a25c-2fda074006b2/page/edBQB',
        }
      },
      {
        path: 'ge-performance-produto',
        name: 'ge-performance-produto',
        component: IFramePage,
        meta: {
          label: 'GE',
          customColors: ['#06AA48', '#ABC90C'],
          requiresPermission: 'isGEDigitalAnalytics',
          description: 'Performance de Produto.<br/>Fonte: Google Analytics',
          imageFile: 'section-icons/ge-branco.png',
          iFrameSrc: 'https://datastudio.google.com/embed/reporting/d7956312-9ba1-42f3-aaaf-31c3010dfc68/page/edBQB',
        }
      },
      {
        path: 'gshow-performance-produto',
        name: 'gshow-performance-produto',
        component: IFramePage,
        meta: {
          label: 'Gshow',
          customColors: ['#EF6900'],
          requiresPermission: 'isGShowDigitalAnalytics',
          description: 'Performance de Produto.<br/>Fonte: Google Analytics',
          imageFile: 'section-icons/gshow-branco.png',
          iFrameSrc: 'https://datastudio.google.com/embed/reporting/1f110143-4b29-4e5a-84ab-a6f71f2009ea/page/edBQB',
        }
      },
      {
        path: 'youtube-jornalismo-esporte',
        name: 'youtube-jornalismo-esporte',
        component: IFramePage,
        meta: {
          label: 'Acompanhamento de Live Youtube',
          customColors: ['#EF6900'],
          isNew: true,
          requiresPermission: 'isYoutubeJournalismAndSportDigitalAnalytics',
          description: 'Quantidade de usuários simultâneos, nas lives de canais monitorados, em realtime. <br/>Fonte: Youtube API',
          imageFile: 'section-icons/digital.png',
          iFrameSrc: 'https://lookerstudio.google.com/embed/reporting/a8284dbb-b817-415b-b185-9bfc55441539/page/tEnnC',
        }
      },
    ],
  },

  //Admin
  {
    path:'/admin',
    name: 'admin',
    component: EmptyRouterView,
    redirect:'/admin/usuarios',
    meta: {
      label: 'Administração',
    },
    children:[
      {
        path: 'usuarios',
        name: 'users',
        component: Users,
        meta: {
          label: 'Usuários',
          customColors: ['#1297DF', '#5EB68C'],
          requiresPermission: 'isPermissionManager',
        }
      },
      {
        path: 'regras',
        name: 'groups',
        component: Groups,
        meta: {
          label: 'Regras',
          customColors: ['#1297DF', '#5EB68C'],
          requiresPermission: 'isPermissionManager',
        }
      },
      {
        path: 'configuracoes',
        name: 'config',
        component: Configs,
        meta: {
          label: 'Configurações',
          customColors: ['#1297DF', '#5EB68C'],
          requiresPermission: 'isPermissionManager',
        }
      },
    ]
  },

  // Generic routes
  {
    path: '/nao-autorizado',
    name: 'userNotAuthorized',
    component: UserNotAuthorized,
    meta: {
      hiddenOnNavigation: true,
    },
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound,
    meta: {
      hiddenOnNavigation: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, _, next) => {
  const { isLogged, currentUser } = store.state.user;

  const isUnprotectedRoute = (name: string | null | undefined) => {
    if (name) return ['login', 'logout'].includes(name);
    return false;
  };

  const isPageUnderMaintenance = (name: string | null | undefined) => {
    if (name && PAGES_UNDER_MAINTENANCE) {
      const pagesMaintenanceList = PAGES_UNDER_MAINTENANCE.split(";");
      return pagesMaintenanceList.includes(name);
    }
    return false;
  }

  // Se for uma das rotas desprotegidas continua, caso contrário inicia as validações
  if (isUnprotectedRoute(to.name)) {
    next();
  } else {

    // Se o usuário não estiver logado, direciona para o login
    if (!isLogged) {
      next({ name: 'login' });
      return;
    }

    // Se houver sessão, mas o usuário não estiver armazenado na store, busca seus dados
    if (isLogged && currentUser === undefined) {
      await store.dispatch('authorize');
    }

    if (isPageUnderMaintenance(to.name)) {
      next({ name: 'pagina-em-manutencao' });
      return;
    }
    // Verifica se o usuário tem permissão para a rota
    RouterUtil.isNextRouteAllowed(to.path, store.state.user.userRoutes) ? next() : next({ name: 'userNotAuthorized' });
    next();
  }
});

export default router;
